#faqs {
  background-color: rgba(90, 90, 90, 1);
  color: whitesmoke;
  padding: 3rem;
}

.accordion-item {
  background-color: unset;
}

.accordion-header {
  background-color: rgba(90, 90, 90, 0.965);
}

.accordion-button {
  background-color: rgba(0, 0, 0, 0.03);
  color: unset;
}

.accordion-button:hover {
  background: #eee8de;
  color: #000;
}

.accordion-button:not(.collapsed) {
  background: #eee8de;
  color: #000;
}

.accordion-button:not(.collapsed) > .dropdown-arrow {
  transform: rotate(90deg);
}

.accordion-button::after {
  display: none;
}

.accordion-body {
  background: #eee8de;
  color: #000;
  border-top: 1px solid #000;
}

.dropdown-arrow {
  margin-right: 1rem;
  transition: transform 0.35s;
  -moz-transition: transform 0.35s;
  -ms-transition: transform 0.35s;
  -o-transition: transform 0.35s;
  -webkit-transition: transform 0.35s;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 3rem 10%;
}