/* general */
* {
  box-sizing: border-box;
}

html,
body,
header,
.view {
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  background-color: rgba(238, 232, 222, 1);
  scroll-behavior: smooth;
}

.container-fluid {
  padding: 0% !important;
  z-index: 5 !important;
}

.section-heading {
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.underline::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #fff;
  transition: width 0.3s;
}

.underline:hover::after {
  width: 100%;
  transition: width 0.3s;
}

a {
  color: whitesmoke;
  text-decoration: none;
}

a:hover {
  color: whitesmoke;
  text-decoration: none;
}

h1 {
  letter-spacing: 8px;
  font-weight: 300;
}

h2 {
  font-weight: 400;
  margin: 0;
}

h5 {
  letter-spacing: 3px;
  font-weight: 300;
}

hr {
  opacity: 1;
  margin: auto;
}

.hr-light {
  border-top: 3px solid black;
  width: 80px;
}

.button:active,
button:active,
.button:focus,
button:focus,
.button:hover,
button:hover {
  outline: none !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
a:focus,
a:active,
a:hover,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.remove-margin {
  margin: 0 !important;
}

.card {
  background-color: rgba(90, 90, 90, 0.965);
  overflow: hidden;
}

/* keyframe stuff */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* media queries */
@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

@media (max-width: 1200px) {
  .about-text-content {
    margin: 0;
  }

  #profilePhotoContainer {
    margin-right: 2rem;
  }
}

@media (max-width: 992px) {
  .navbar-brand {
    margin-left: 0;
  }

  #profilePhotoContainer {
    padding: 0 10% 3rem !important;
    width: 100%;
    margin: 0;
  }

  .about-content {
    flex-direction: column;
  }

  .about-text-content {
    margin: 0;
  }

  #additional-recommendations {
    display: block;
  }

  .additional-recommendation {
    width: 100%;
  }

  .navbar-collapse {
    border-top: 1px solid black !important;
    padding: 1rem 0 0.5rem !important;
    margin-top: 0.5rem;
  }
}

@media (max-width: 768px) {
  #aboutMe {
    padding: 3rem !important;
  }

  #recommendations {
    padding: 3rem 1rem;
  }

  .video-responsive {
    padding-bottom: 80%;
  }

  .video-responsive iframe {
    padding: 3rem 1rem;
  }

  #faq {
    padding: 10% 5% !important;
  }

  .faqBtn {
    width: 100% !important;
  }

  .faqCard {
    margin: 0 !important;
    padding: 0 !important;
  }

  #contact {
    padding: 10%;
  }

  #contact-methods {
    flex-direction: column;
  }

  .contact-method {
    margin-bottom: 1rem;
  }

  #copyright {
    padding: 0% 10%;
  }
}

@media (max-width: 480px) {
  .video-responsive {
    padding-bottom: 100%;
  }

  .video-responsive iframe {
    padding: 3rem 0;
  }
}