#contact {
  background-color: rgba(238, 232, 222, 1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

#contact-form {
  width: 70%;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  border-radius: 0px;
  border-bottom: 2px solid rgba(90, 90, 90, 0.95);
  height: 45px !important;
  color: black;
}

.form-control:focus {
  background-color: transparent !important;
  outline: none !important;
  color: rgba(90, 90, 90, 0.95);
}

.form-control::placeholder {
  color: rgba(90, 90, 90, 0.95);
  opacity: 0.7;
}

.messageForm {
  transition: 0.6s all ease;
}

.messageForm:focus {
  height: 120px !important;
}

.contactBtn {
  background: rgba(86, 80, 80, 0.1);
  color: black;
  border: 2px solid rgba(90, 90, 90, 0.95);
  width: 50% !important;
  overflow: hidden;
  transition: 0.6s all ease;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
