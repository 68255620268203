#about {
  background-color: rgb(90, 90, 90);
  color: whitesmoke;
  padding: 3rem;
}

.about-content {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.about-text-content {
  margin-right: 3rem;
}

#profilePhotoContainer {
  width: 40%;
}

#profilePhoto {
  width: 100%;
  border-radius: 5%;
  background-size: cover;
  border: 10px solid rgba(238, 232, 222, 1);
  max-height: 350px;
  max-width: 350px;
}
