.logo {
  width: 40px;
}

.navbar {
  background-color: rgba(238, 232, 222, 1) !important;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(90, 90, 90, 0.5) !important;
  padding: 0.5rem 1rem;
}

.navbar-brand {
  margin: 0 1rem;
}

.navbar-collapse {
  justify-content: flex-end;
}

.nav-item {
  margin: 0 0.3rem;
}

.nav-link {
  width: fit-content;
}

.background {
  background-image: url("/src/assets/A_level_maths.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.centerTitle {
  margin-top: 200px;
  border-radius: 5px;
  padding: 10px;
  background-color: rgba(238, 232, 222, 0.85);
}

.main-title {
  text-indent: 8px;
}

.sub-title {
  margin-bottom: 1rem !important;
  text-indent: 3px;
}
