#copyright {
  background-color: rgba(238, 232, 222, 1);
  color: rgba(90, 90, 90, 1) !important;
  display: flex;
  flex-direction: column;
}

#contactDetails {
  background-color: rgba(238, 232, 222, 1);
}

#contact-methods {
  display: flex;
  width: 100%;
}

.contact-method {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.a-contact {
  color: rgba(90, 90, 90, 0.95);
  margin: 0.5rem;
}

.contactBtn:hover,
.reviewBtn:hover {
  background: rgba(86, 80, 80, 0.6);
  color: whitesmoke;
  border: 2px solid rgba(86, 80, 80, 0.6);
}
