#testimonials {
  background-color: rgba(238, 232, 222, 1);
  padding: 3rem;
}

.font-italic {
  font-style: italic;
}

.carousel {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.carousel-inner {
  height: fit-content;
  display: flex;
  align-items: center;
}

.carousel-item {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  position: unset;
  opacity: 0;
  transition: opacity 1s !important;
}

.carousel-item.active {
  opacity: 1;
  transition: opacity 1s !important;
}

.carousel-caption {
  width: 100%;
  text-align: right;
  position: unset;
  right: 0;
  left: 0;
  padding: 0;
  color: black;
}

.caption-text {
  font-weight: 400;
}

.carousel-indicators {
  position: inherit;
  order: 2;
  margin: auto;
  max-width: 100%;
}

.carousel-text {
  margin: 0;
}

#write-review {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.reviewBtn {
  background: rgba(86, 80, 80, 0.1);
  color: black;
  border: 2px solid rgba(90, 90, 90, 0.95) !important;
  width: 50% !important;
  overflow: hidden;
  transition: 0.6s all ease;
}
